.fw-light {
    font-weight: 300 !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: bold !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-center {
    text-align: center;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.w-auto {
    width: auto !important;
}

.text-white {
    color: #ffffff !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-3 {
    padding-left: 12px !important;
}

.m-0 {
    margin: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 4px !important;
}

.mb-2 {
    margin-bottom: 8px !important;
}

.mb-3 {
    margin-bottom: 12px !important;
}

.mr-1 {
    margin-right: 4px !important;
}

.mr-2 {
    margin-right: 8px !important;
}

.mr-3 {
    margin-right: 12px !important;
}

.mt-1 {
    margin-top: 4px !important;
}

.mt-2 {
    margin-top: 8px !important;
}

.mt-3 {
    margin-top: 12px !important;
}

.m-4,
.mt-4 {
    margin-top: 24px !important;
}

.m-4,
.mb-4 {
    margin-bottom: 24px !important;
}

.m-4,
.ml-4 {
    margin-left: 24px !important;
}

.m-4,
.mr-4 {
    margin-right: 24px !important;
}

.ml-1 {
    margin-left: 4px !important;
}

.ml-2 {
    margin-left: 8px !important;
}

.ml-3 {
    margin-left: 12px !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.list-inline {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.d-none {
    display: none;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.list-style-none {
    padding-left: 0;
    list-style-type: none;
    margin: 0;
}

.b-0 {
    border: 0 !important;
}

.w-100 {
    width: 100% !important;
}

@media (min-width: 575px) {
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
}
