@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "./helper.less";
@import "./theme.less";

// Font Face Rule
.font-face (@font-family, @font-name, @font-weight, @font-style) {
    @font-face {
        font-family: @font-family;
        src: url("../font/Inter/@{font-name}.woff2") format("woff2");
        font-weight: @font-weight;
        font-style: @font-style;
        font-display: swap;
    }
}

.font-face("Inter", "Inter-Light", 300, normal);
.font-face("Inter", "Inter-Regular", 400, normal);
.font-face("Inter", "Inter-Medium", 500, normal);
.font-face("Inter", "Inter-SemiBold", 600, normal);
.font-face("Inter", "Inter-Bold", 700, normal);
.font-face("Inter", "Inter-ExtraBold", 800, normal);

::selection {
    color: @text-color;
    background: fade(@primary-color, 20%);
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -0.01em;
}

a {
    transition: unset;
}

.border-bottom {
    border-bottom: 1px solid #e8ecf1;
}

.table-responsive {
    overflow: auto;
}

.ant-input-suffix {
    pointer-events: auto;
}

.iti {
    width: 100%;
}

.ant-btn-success {
    background-color: @success-color;
    border-color: @success-color;
    color: @color-white;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
        color: @color-white;
        background-color: @success-color;
        border-color: @success-color;
    }
}

.ant-statistic-content-value,
.ant-statistic-content,
.ant-pagination-item,
.ant-calendar-picker-container,
.ant-steps-item-icon,
input,
button,
select,
optgroup,
textarea {
    font-family: @font-family  !important;
}

.page-title {
    font-size: 36px;
    font-weight: 600;
}

.ant-input,
.ant-btn,
.ant-select {
    transition: unset;
}

// Feather
.if-20 {
    width: 20px !important;
    height: 20px !important;
    line-height: 34px !important;
    vertical-align: text-bottom;

    svg {
        width: 20px;
        height: 20px;
    }
}

// Drawer
.ant-drawer-title {
    font-weight: 700;
    font-size: 18px;
}

// Button
.ant-btn-round {
    height: 36px;
    font-weight: 600;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    span {
        line-height: 34px;
    }

    &.ant-btn-lg {
        padding: 0 24px;
        height: 46px;

        span {
            line-height: 44px;
        }
    }

    &.ant-btn-sm {
        span {
            line-height: 22px;
        }
    }
}

// Table
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1180px;
    }

    @media (min-width: 1440px) {
        max-width: 1400px;
    }
}

.user-name {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-right: 6px;
        width: 20px;
        height: 20px;
    }

    span {
        color: #039be5;
        font-weight: 500;
        font-size: 16px;
    }
}

.contact-person-block {
    text-align: center;
    margin: 20px 0;

    .user-avatar {
        width: 90%;
        height: 290px;
        border-radius: 20px;
        object-fit: cover;
        margin-bottom: 12px;
    }

    h6 {
        margin-bottom: 0;
        font-size: 28px;
        font-weight: 800;
    }

    .email-contact-text {
        .user-name();
        margin-top: 3px;

        img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }

        span {
            color: #333333;

            &:hover {
                color: #9a00ab;
            }
        }
    }
}

.user-container-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
}

.user-container-breadcrumb-title {
    .ant-breadcrumb-separator {
        font-size: 12px;
        vertical-align: middle;
        line-height: normal;
    }

    nz-breadcrumb-item {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 20px;
    }

    .ant-breadcrumb {
        margin-bottom: 24px;
        color: inherit;

        a {
            color: inherit;
        }
    }
}


// Text 
.text-primary {
    color: @primary-color  !important;
}

.text-black {
    color: @text-color  !important;
}

.text-danger {
    color: @error-color  !important;
}

// Input
input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.ant-picker,
.ant-input,
.ant-input-affix-wrapper {
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
}

.ant-form-item-label {
    padding-bottom: 4px;

    label {
        font-weight: 500;
        font-size: 15px;
        line-height: normal;
    }
}

// Selector
.ant-select-selector {
    border-radius: 6px;
    height: 36px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;

    .ant-select-selection-search-input {
        height: 36px;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 34px;
}

// Radio 
.ant-radio-checked {
    .ant-radio-inner {
        border-color: @primary-color  !important;
    }
}

span.ant-radio+* {
    font-size: 16px;
}

// Checkbox
.ant-checkbox-wrapper {
    span {
        user-select: none;
        font-size: 15px;
    }
}

.ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-color: #c5c5c5;
    border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1) translate(-50%, -60%);
}

.ant-checkbox-wrapper {
    span {
        font-size: 16px;
    }
}

// Modal
.ant-modal-title {
    font-weight: 800;
    font-size: 20px;
}

.ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 24px 24px 0 24px;
    border: 0;
}

.ant-modal-content {
    border-radius: 12px;
}

.ant-modal-footer {
    padding: 0 24px 24px;
    border: 0;
}

.ant-modal-close {
    top: 18px;
    right: 18px;
    border-radius: 100px;
    color: #808080 !important;

    &:hover {
        color: #808080;
        background: #f3f3f3;
    }
}

.ant-modal-close-x {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    i-feather {
        line-height: normal;
    }
}

.custom-input {
    .ant-input-affix-wrapper {
        border-radius: 4px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
    }
}

.cart-section,
.gear-section,
.checkout-section {
    .ant-alert-info {
        background-color: #e6f7ff;
        border: 0;
        margin: 0 0 10px 0;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;

        @media (max-width: 575px) {
            flex-direction: column;
        }

        .ant-alert-icon {
            position: relative;
            top: 0;
            left: 0;
            color: #00abfb;
            margin-right: 8px;
            font-size: 24px;
        }
    }

    .ant-alert-content {
        flex: 0 0 auto;
    }
}

.cart-section,
.calculator_section,
.checkout-section {
    background: #f9f9f9;
}

.calculator_section {
    min-height: 100vh;
    padding: 50px 0;
}

.cart-section,
.checkout-section {
    padding: 30px 0px;
    min-height: 100ch;
}

.cart-total-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin: 14px 0px;

    &.cart-total-amount {
        font-weight: 800;
        font-size: 18px;
        padding: 15px 0px;
        margin: 0 0 20px 0;
        border-top: 1px dashed #e0e0e0;
        border-bottom: 1px dashed #e0e0e0;
    }

    .cart-type {
        margin-right: 12px;
    }

    .cart-total {
        flex: 1 0 auto;
        text-align: right;
        word-break: break-word;
    }
}

.map-bg {
    z-index: 1;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url("../img/png/map.png"), #f9f9f9;
        background-repeat: no-repeat;
        background-size: cover;
        filter: grayscale(100%);
        z-index: -1;
    }

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        background-color: rgba(249, 249, 249, 0.6);
    }
}

.checkout-action-btn {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 475px) {
        flex-direction: column;

        .ant-btn {
            width: 100%;

            &:first-child {
                margin: 0 0 16px 0 !important;
            }
        }
    }
}

// Hosting
.ppa-section,
.hosting-custom-section {
    padding: 50px 0;
    min-height: calc(100vh - 468px);
}

.hosting-section {
    .map-bg();
}

.ppa-section,
.hosting-custom-section {
    .hosting-custom-card {
        max-width: 1380px;
        border-radius: 12px;
        margin: auto;
        padding: 75px 30px;
        background-color: #fff;
        z-index: 1;
        position: relative;
        box-shadow: 0 6px 24px #0000001a;

        &.form-card {
            padding: 50px 30px;
        }
    }

    // Media Quiries
    @media only screen and (max-width: 575px) {

        .calculator_section,
        .cart-section,
        .checkout-section {
            padding: 25px 0;
            min-height: 75ch;
        }
    }
}

// Media 
@media (max-width: 575px) {
    .ant-modal {
        top: 20px;
    }
}
